import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, StackDivider, Text, VStack } from '@chakra-ui/react';
import type { AccountPaymentsGetContractDetailsResponse } from '@netiva/classifieds-api';
import { hasFlag } from '@netiva/classifieds-common';

import { PriceSummary } from '@/components/ui';
import { useDate } from '@/hooks';
import { useAppSelector } from '@/store';

export type ContractDetailsProps = {
  contract: AccountPaymentsGetContractDetailsResponse;
};

export const ContractDetails: FC<ContractDetailsProps> = ({ contract }) => {
  const { t } = useTranslation();
  const { formatDate } = useDate();
  const { account } = useAppSelector((x) => x.account);

  const requiresPrepayment = hasFlag(account?.accountStatus, 'RequiresPrepayment');
  const payBefore = formatDate(contract.payBefore, true);

  return (
    <VStack align="stretch" divider={<StackDivider />} spacing={4}>
      <Box>
        <Text>
          {payBefore
            ? requiresPrepayment
              ? t('payment.message.withDate.requiresPrepayment', { date: payBefore })
              : t('payment.message.withDate', { date: payBefore })
            : requiresPrepayment
              ? t('payment.message.withoutDate.requiresPrepayment')
              : t('payment.message.withoutDate')}
        </Text>
      </Box>
      <PriceSummary
        productId={contract.product.id}
        extensionProductIds={contract.extensionProducts.map((ext) => ext.id)}
        billingAddressId={contract.billingAddressId}
        maxWidth="500px"
      />
    </VStack>
  );
};
