import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  SkeletonText,
  Text,
  VStack,
  Wrap,
  chakra,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { accountApi, AccountCategoryCategoryListResponseCategory, publicApi } from '@netiva/classifieds-api';
import { flattenTree, useDebounce } from '@netiva/classifieds-common';
import { Loader, SelectableTag } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdCategory } from '@/store/ad/types';
import { AdComponentProps } from '@/pages/Ad/types';
import { useAdParams } from '@/pages/Ad/hooks';

export const CategorySelector: FC<AdComponentProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId, categorySearchText } = useAppSelector((state) => state.ad);
  // only leaf categories are valid selections, so we only set the category in redux when a leaf category is selected, for parent categories we use this local state
  const [parentCategoryId, setParentCategoryId] = useState<number>();
  // get request params
  const { category: categoryParam } = useAdParams();

  // load categories
  const { data: categoriesData, isLoading } = accountApi.useGetCategories({ recursive: true });
  const categoryTree = useMemo<AdCategory[]>(
    () => categoriesData?.categories.slice().sort((a, b) => a.name!.localeCompare(b.name!)) || [],
    [categoriesData?.categories]
  );
  const allCategories = useMemo(
    () => flattenTree(categoriesData?.categories || [], (c) => c.children),
    [categoriesData]
  );

  // load suggestions
  const debouncedSearchText = useDebounce(categorySearchText, 500);
  const { data: searchResults, isFetching: isLoadingSuggestions } = publicApi.useGetDataObjects(
    { query: debouncedSearchText },
    { skip: !debouncedSearchText }
  );
  const suggestedIds = useMemo(
    () => [...new Set(searchResults?.items.map((i) => i.categoryId))].slice(0, 5),
    [searchResults]
  );

  // on component mount check for existing categoryParam
  useEffect(() => {
    if (categoryParam && allCategories) {
      const initialCategory = allCategories.find((c) => c.id === categoryParam);
      if (initialCategory) selectCategory(initialCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryParam, allCategories]);

  const getCategoryPath = useCallback(
    (categoryId: number | null | undefined) => {
      const result: AdCategory[] = [];

      let id: number | null | undefined = categoryId;
      do {
        const tmpId: number | null | undefined = id;
        const category: AdCategory | undefined = allCategories.find((c) => c.id === tmpId);

        if (category) {
          result.push(category);
        }
        id = category?.parentId;
      } while (id);
      return result.reverse();
    },
    [allCategories]
  );

  const categoryPath = useMemo(
    () => getCategoryPath(categoryId || parentCategoryId),
    [categoryId, getCategoryPath, parentCategoryId]
  );

  const selectCategory = useCallback(
    (category?: AdCategory) => {
      if (category && category.children && category.children.length > 0) {
        dispatch(adActions.setCategoryId(undefined));
        setParentCategoryId(category.id);
      } else {
        console.log(category);
        dispatch(adActions.setCategoryId(category?.id));
        setParentCategoryId(undefined);
      }
    },
    [dispatch]
  );

  const renderCategoryTag = useCallback(
    (category: AdCategory) => {
      return (
        <SelectableTag
          key={category.id}
          fontSize="md"
          fontWeight="bold"
          onClick={() => selectCategory(category)}
          isSelected={category.id === categoryId}
        >
          {category.name}
        </SelectableTag>
      );
    },
    [categoryId, selectCategory]
  );

  const renderSuggestion = useCallback(
    (categoryPath: AccountCategoryCategoryListResponseCategory[]) => {
      return (
        <SelectableTag
          key={categoryPath[categoryPath.length - 1].id}
          onClick={() => selectCategory(categoryPath[categoryPath.length - 1])}
          fontSize="md"
        >
          <HStack divider={<ChevronRightIcon border="none" />} wrap="wrap" gap={2} spacing={0}>
            {categoryPath.map((c) => (
              <Text key={c.id}>{c.name}</Text>
            ))}
          </HStack>
        </SelectableTag>
      );
    },
    [selectCategory]
  );

  const renderSuggestions = useCallback(
    (suggestedIds: number[]) => {
      const categoryPaths = suggestedIds.map((id) => {
        const categoryPath = getCategoryPath(id);
        if (!categoryPath.length) {
          return null;
        }
        return categoryPath;
      });

      categoryPaths.sort((a, b) => {
        const nameA = a?.[0].name || '';
        const nameB = b?.[0].name || '';

        return nameA.localeCompare(nameB);
      });

      return <>{categoryPaths.map((categoryPath) => categoryPath && renderSuggestion(categoryPath))}</>;
    },
    [getCategoryPath, renderSuggestion]
  );

  return (
    <VStack align="stretch" spacing={4} position="relative">
      <Loader isLoading={isLoading} showOverlay />
      <FormControl>
        <FormLabel>{t('ad.steps.category.search.label')}</FormLabel>
        <Input
          type="text"
          autoFocus
          value={categorySearchText}
          onChange={(e) => dispatch(adActions.setCategorySearchText(e.target.value))}
        />
        <FormHelperText>{t('ad.steps.category.search.description')}</FormHelperText>
      </FormControl>
      {(suggestedIds.length > 0 || isLoadingSuggestions) && (
        <>
          <VStack align="stretch">
            <Text>{t('ad.steps.category.suggestions')}</Text>
            {isLoadingSuggestions ? (
              <SkeletonText noOfLines={suggestedIds.length || 2} skeletonHeight={10} />
            ) : (
              renderSuggestions(suggestedIds)
            )}
          </VStack>
          <Divider my={8} />
        </>
      )}
      {categoryPath.length > 0 && (
        <>
          <Text>{t('ad.steps.category.chosenCategory')}</Text>
          <HStack wrap="wrap" gap={2} spacing={0} divider={<ChevronRightIcon border="none" />}>
            <SelectableTag fontSize="md" fontWeight="bold" onClick={() => selectCategory(undefined)}>
              {t('ad.steps.category.allCategories')}
            </SelectableTag>
            {categoryPath.map(renderCategoryTag)}
          </HStack>
        </>
      )}
      {categoryPath.length === 0 && (
        <>
          <Text>{t('ad.steps.category.chooseCustom')}</Text>
          <Wrap spacing={3}>{categoryTree.map(renderCategoryTag)}</Wrap>
        </>
      )}
      {categoryPath.length > 0 && categoryPath[categoryPath.length - 1].children && (
        <>
          <Divider my={4} />
          <Text>
            {t('ad.steps.category.chooseCategory')} <chakra.span color="red">*</chakra.span>
          </Text>
          <Wrap spacing={3}>{categoryPath[categoryPath.length - 1].children!.map(renderCategoryTag)}</Wrap>
        </>
      )}
    </VStack>
  );
};
