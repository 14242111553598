import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiPrinter } from 'react-icons/fi';
import { DataObjectStatus, accountApi, publicApi } from '@netiva/classifieds-api';
import { useDebounce, useLocalStorageRedux } from '@netiva/classifieds-common';
import { ConfirmationDialog, GridIcon, ListIcon, Loader, Pagination } from '@netiva/classifieds-ui';

import { RouterLink } from '@/components/global';
import { usePagination } from '@/hooks';
import { routes } from '@/lib/routes';
import { useAppSelector } from '@/store';
import { AdGrid, AdList, Filter, UneditableModal } from './components';
import { AdListItem, AdListItemAction, AdListLayoutType, FilterStatus } from './types';
import { CommonStepKeys } from '../Ad/constants';
import { useAdListSearchParams } from './hooks';
import { getDataObjectStatus, getFilterCounts } from './utils';

const PageSize = 20;

export const MyAdsPage: FC = memo(function MyAdsPage() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [isStatusChangeHandled, setIsStatusChangeHandled] = useState(false);
  const [searchParams, setSearchParams] = useAdListSearchParams();

  const { accountId } = useAppSelector((state) => state.account);

  publicApi.useGetConfigurationPlatforms({});
  const { platforms } = useAppSelector((state) => state.global);
  const printablePlatforms = useMemo(() => platforms.filter((p) => p.allowPrintList), [platforms]);

  const [layoutType, setLayoutType] = useLocalStorageRedux<AdListLayoutType>('layoutType', 'list');

  const debouncedSearchParams = useDebounce(searchParams, 200);

  const statusTabs = useMemo<{ status: FilterStatus; label: string }[]>(
    () => [
      { status: 'active', label: t('ad.status.active') },
      { status: 'draft', label: t('ad.status.draft') },
      { status: 'inactive', label: t('ad.status.disabled') },
    ],
    [t]
  );
  const statusTabIndex = useMemo(() => {
    const index = statusTabs.findIndex((x) => x.status === searchParams.status);
    return index < 0 ? 0 : index;
  }, [searchParams.status, statusTabs]);
  const statusParam = useMemo<DataObjectStatus[]>(
    () => getDataObjectStatus(searchParams.status || 'active'),
    [searchParams]
  );

  const {
    data: adList,
    isLoading: adListLoading,
    isFetching: adListFetching,
  } = accountApi.useGetDataObjects({
    status: statusParam,
    filter: debouncedSearchParams.query,
    platform: debouncedSearchParams.platform !== '' ? debouncedSearchParams.platform : undefined,
    topListed: debouncedSearchParams.topListed,
    pageIndex: debouncedSearchParams.pageIndex,
    pageSize: PageSize,
  });
  const items = useMemo(() => adList?.items || [], [adList?.items]);
  const { data: countsData, isLoading: isLoadingCounts } = accountApi.useDataObjectCounts({});
  const counts = useMemo(() => getFilterCounts(countsData?.counts), [countsData?.counts]);
  const [activateDataObject, { isLoading: isLoadingActivate }] = accountApi.useActivateDataObject();
  const [deactivateDataObject, { isLoading: isLoadingDeactivate }] = accountApi.useDeactivateDataObject();
  const [deleteDataObject, { isLoading: isLoadingDelete }] = accountApi.useDeleteDataObject();
  const [duplicateDataObject, { isLoading: isLoadingDuplicate }] = accountApi.useDuplicateDataObject();
  const [setTopListing, { isLoading: isLoadingTopListing }] = accountApi.useSetTopListing();

  const hasTopListingSlots = !!adList && adList.totalTopListingSlots > adList.usedTopListingSlots;
  const totalPages = Math.ceil((adList?.totalCount || 0) / PageSize);
  const paginationProps = usePagination({ totalPages });

  const [selectedObject, setSelectedObject] = useState<AdListItem>();
  const uneditableModal = useDisclosure();

  const [deletingObjectId, setDeletingObjectId] = useState<number>();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();

  const handleDeleteConfirmation = async () => {
    if (deletingObjectId) {
      await deleteDataObject({ id: deletingObjectId });
      setDeletingObjectId(undefined);
      closeDeleteConfirmation();
    }
  };
  const handleDeleteCancel = () => {
    setDeletingObjectId(undefined);
    closeDeleteConfirmation();
  };

  const setTopListingWrapper = async (id: number, active: boolean) => {
    try {
      await setTopListing({ id, active }).unwrap();
      toast({
        status: 'success',
        description: active ? t('myads.actions.enableTopListingSuccess') : t('myads.actions.disableTopListingSuccess'),
      });
    } catch (err) {
      toast({
        status: 'error',
        description: active ? t('myads.actions.enableTopListingError') : t('myads.actions.disableTopListingError'),
      });
    }
  };

  const handleAction = async (item: AdListItem, action: AdListItemAction) => {
    switch (action) {
      case 'edit':
        navigate(routes.ad(item.id, CommonStepKeys.edit));
        break;

      case 'delete':
        setDeletingObjectId(item.id);
        openDeleteConfirmation();
        break;

      case 'disable':
        deactivateDataObject({ id: item.id });
        break;

      case 'duplicate':
        {
          const result = await duplicateDataObject({ id: item.id }).unwrap();
          navigate(routes.ad(result.id, CommonStepKeys.edit));
        }
        break;

      case 'enable':
        activateDataObject({ id: item.id });
        break;

      case 'enableTopListing':
        setTopListingWrapper(item.id, true);
        break;

      case 'disableTopListing':
        setTopListingWrapper(item.id, false);
        break;

      case 'extendDuration':
        navigate(routes.ad(item.id, 'publishing'));
        break;

      case 'showUneditableModal':
        setSelectedObject(item);
        uneditableModal.onOpen();
        break;
    }
  };

  const handleStatusTabChange = (tabIndex: number) => {
    let status: FilterStatus = '';
    switch (tabIndex) {
      case 0:
        status = 'active';
        break;
      case 1:
        status = 'draft';
        break;
      case 2:
        status = 'inactive';
        break;
    }
    if (searchParams.status !== status) {
      setSearchParams({ ...searchParams, status });
    }
  };

  useEffect(() => {
    if (isLoadingCounts || searchParams.status !== 'active' || isStatusChangeHandled) {
      return;
    }
    if (!counts['active']) {
      if (counts['draft']) {
        setSearchParams({ ...searchParams, status: 'draft' }, true);
      } else if (counts['inactive']) {
        setSearchParams({ ...searchParams, status: 'inactive' }, true);
      } else {
        setSearchParams({ ...searchParams, status: 'active' }, true);
      }
    }
    setIsStatusChangeHandled(true);
  }, [counts, isLoadingCounts, isStatusChangeHandled, searchParams, setSearchParams]);

  const hasFilter =
    searchParams.platform || searchParams.query || searchParams.topListed || searchParams.status !== 'active';

  const isLoading =
    adListLoading ||
    adListFetching ||
    isLoadingCounts ||
    isLoadingActivate ||
    isLoadingDeactivate ||
    isLoadingDelete ||
    isLoadingDuplicate ||
    isLoadingTopListing;

  const ListComponent = layoutType === 'grid' ? AdGrid : AdList;

  return (
    <>
      <Tabs isLazy size="lg" marginBottom={4} index={statusTabIndex} onChange={handleStatusTabChange}>
        <TabList>
          {statusTabs.map((tab, i) => (
            <Tab key={i}>
              {tab.label}
              {!isLoadingCounts && (
                <Badge fontSize="md" ml={2}>
                  {counts[tab.status] || 0}
                </Badge>
              )}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <Filter />
      <Divider my={4} />

      <Flex align="center" justify="space-between" gap={2}>
        <Text fontSize="large">{t('myads.listTitle', { count: adList?.totalCount || 0 })}</Text>
        {!!adList?.totalTopListingSlots && (
          <Text>
            {t('myads.availableTopListings', {
              available: adList.totalTopListingSlots - adList.usedTopListingSlots,
              total: adList.totalTopListingSlots,
            })}
          </Text>
        )}
        <Flex>
          <IconButton
            icon={<ListIcon boxSize={6} />}
            variant={layoutType === 'list' ? 'iconButtonActive' : 'iconButton'}
            aria-label={'List'}
            onClick={() => setLayoutType('list')}
            ml={1}
          />
          <IconButton
            icon={<GridIcon boxSize={6} />}
            variant={layoutType === 'grid' ? 'iconButtonActive' : 'iconButton'}
            aria-label={'Grid'}
            onClick={() => setLayoutType('grid')}
          />
        </Flex>
      </Flex>

      {!isLoading && items.length === 0 && (
        <Alert status="info">{hasFilter ? t('myads.emptyFiltered') : t('myads.empty')}</Alert>
      )}

      <Divider my={4} />

      <Box position="relative" minHeight="200px">
        <Loader isLoading={isLoading} showOverlay />
        <ListComponent
          items={items}
          onActionExecuted={handleAction}
          showPlatform={platforms.length > 1}
          hasTopListingSlots={hasTopListingSlots}
        />
      </Box>

      <Flex mt={4} justify="center" align="center">
        <Pagination {...paginationProps} />
      </Flex>

      {accountId && printablePlatforms.length && (
        <Box>
          <Divider my={4} />
          {printablePlatforms.map((platform) => (
            <RouterLink
              key={platform.name}
              to={routes.myAdsPrint(accountId, platform.name, i18n.language)}
              target="_blank"
            >
              <Flex gap={2} align="center" my={2}>
                <Icon as={FiPrinter} boxSize={6} />
                <Text>{t('myads.printPlatformList', { platform: platform.name })}</Text>
              </Flex>
            </RouterLink>
          ))}
        </Box>
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmation}
        cancelText={t('ui.buttons.cancel')}
        okText={t('ui.buttons.delete')}
        title={t('myads.actions.deleteConfirmation.title')}
        message={t('myads.actions.deleteConfirmation.message')}
      />

      <UneditableModal item={selectedObject} {...uneditableModal} />
    </>
  );
});
