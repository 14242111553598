import { useSearchParams } from 'react-router-dom';

import { useAuth } from '@/hooks';

export const useAuthToken = () => {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('token');
  const { isLoading, isAuthenticated } = useAuth();
  const token = !isAuthenticated && !isLoading && authToken ? authToken : undefined;
  return { token, isLoading };
};
