import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';

import { SelectableCard } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttributeEntry } from '@/store/ad/types';
import { AdComponentProps } from '@/pages/Ad/types';

const Container = chakra('div', {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)', 'repeat(5, 1fr)'],
    gridTemplateRows: 'repeat(auto-fill, auto)',
    gridRowGap: '2',
    gridColumnGap: '2',
  },
});

const ModalContentStyles: SystemStyleObject = {
  fontSize: 'lg',

  '& ul': {
    marginX: 0,
    marginY: 2,
    padding: 0,

    '& li': {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      paddingX: 0,
      paddingY: 1,

      '& img': {
        marginRight: 2,
        width: 'auto',
        height: 'auto',
        maxHeight: '22px',
      },
    },
  },
};

export const StyleSelector: FC<AdComponentProps> = ({ attribute }) => {
  const { t } = useTranslation();
  const { formData } = useAppSelector((state) => state.ad);
  const dispatch = useAppDispatch();
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  const handleClick = (option: AdAttributeEntry) => {
    if (attribute) {
      dispatch(adActions.updateFormData({ key: attribute.id, entries: [option.id] }));
    }
  };

  return (
    <Container>
      {attribute &&
        attribute.items.map((option) => (
          <SelectableCard
            key={option.id}
            onClick={() => handleClick(option)}
            isSelected={formData[attribute.id]?.entries?.[0] === option.id}
          >
            <chakra.img src={`/assets/images/color-${option.key}.png`} alt={option.name || ''} boxShadow="default" />
            <Text fontWeight="bold" textAlign="center" mt={4}>
              {option.name}
            </Text>
          </SelectableCard>
        ))}
      <SelectableCard fontWeight="bold" textAlign="center" onClick={() => setIsCustomModalOpen(true)}>
        <chakra.img src={`/assets/images/color-custom.png`} alt={t('ad.steps.style.custom')} boxShadow="default" />
        <Text fontWeight="bold" textAlign="center" mt={4}>
          {t('ad.steps.style.custom')}
        </Text>
      </SelectableCard>
      <Modal isOpen={isCustomModalOpen} onClose={() => setIsCustomModalOpen(false)}>
        <ModalOverlay />
        <ModalContent maxWidth="500px">
          <ModalCloseButton />
          <ModalHeader>{t('ad.steps.style.custom.modalTitle')}</ModalHeader>
          <ModalBody
            sx={ModalContentStyles}
            dangerouslySetInnerHTML={{ __html: t('ad.steps.style.custom.modalTextHtml') }}
          ></ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
